<template>
	<div class="goods_list_box">
		<Classification
			:types="1"
			:navData="$store.state.navData"
			:sidebarData="$store.state.sidebarData"
		></Classification>
		<div class="goods_list_content">
			<div class="title flex-center">产品>{{ titleName }}</div>
			<div class="classification_box">
				<div class="list flex">
					<div class="level nowrap">一级分类:</div>
					<div class="classification_list flex flex-wrap">
						<div
							class="classification_name pointer"
							:class="classifyActive == item.id ? 'active' : ''"
							@click="classifyDataBtn(index, item)"
							v-for="(item, index) in classifyData"
							:key="index"
						>
							{{ item.label }}
						</div>
					</div>
				</div>
				<div class="list flex">
					<div class="level nowrap">二级分类:</div>
					<div class="classification_list flex flex-wrap">
						<div
							class="classification_name pointer"
							:class="secondLevelActive == item.id ? 'active' : ''"
							@click="secondLevelBtn(index, item)"
							v-for="(item, index) in secondLevel"
							:key="index"
						>
							{{ item.label }}
						</div>
					</div>
				</div>
				<div class="list flex">
					<div class="level nowrap">三级分类:</div>
					<div class="classification_list flex flex-wrap">
						<div
							class="classification_name pointer"
							:class="threeActive == item.id ? 'active' : ''"
							@click="threeLevelBtn(index, item)"
							v-for="(item, index) in threeLevel"
							:key="index"
						>
							{{ item.label }}
						</div>
					</div>
				</div>
			</div>
			<div class="sorting_box Between">
				<div class="sorting_fl flex">
					<div class="sorting display">综合排序</div>
					<div class="sales display pointer" @click="changeSaleseBtn">
						销量
						<img
							:src="params.isSales == 1 ? icon_upImg : icon_downImg"
							alt=""
						/>
					</div>
					<div class="sales display pointer" @click="changePriceBtn">
						价格
						<img
							:src="params.isPrice == 1 ? icon_upImg : icon_downImg"
							alt=""
						/>
					</div>
				</div>
				<div class="sorting_fr flex-center">
					<div class="goodsNum">共 {{ total }} 件相关商品</div>
					<div
						class="list bigImg display pointer"
						:class="isList == 1 ? 'active' : ''"
						@click="changeList(1)"
					>
						<img :src="isList == 1 ? lists_y : lists_n" alt="" />
						大图
					</div>
					<div
						class="list display pointer"
						:class="isList == 2 ? 'active' : ''"
						@click="changeList(2)"
					>
						<img :src="isList == 2 ? list_y : list_n" alt="" />
						列表
					</div>
				</div>
			</div>
			<div class="noGoods_box flex-center" v-if="total == 0">
				<img class="icon_noData" src="@/assets/img/noGoods.png" alt="" />
				<p class="flex-center">
					抱歉，没有找到“
					<span>{{ titleName }}</span> ”的商品，我们为您推荐以下相关商品。
				</p>
			</div>
			<div class="goods_big_box" v-if="isList == 1">
				<GoodsList
					v-if="goodsList"
					:goodsList="goodsList"
					@collectBtn="collectBtn"
				></GoodsList>
			</div>
			<div class="goods_list_box" v-else>
				<GoodsLists
					v-if="goodsList"
					:goodsList="goodsList"
					@collectBtn="collectBtn"
				></GoodsLists>
			</div>

			<div class="pageBox" v-if="total != 0">
				<Pages :total="total" :params="params" @jump="pageSkip"></Pages>
			</div>
		</div>
	</div>
</template>   
<script>
import Classification from '@/components/classification.vue'
import Pages from '@/components/pages.vue'
import GoodsList from './components/list.vue'
import GoodsLists from './components/lists.vue'
export default {
	components: {
		Classification,
		GoodsList,
		GoodsLists,
		Pages,
	},
	data() {
		return {
			isList: 1,
			icon_upImg: require('@/assets/img/goodsDetails/upImg.png'),
			icon_downImg: require('@/assets/img/goodsDetails/downImg.png'),
			list_n: require('@/assets/img/goodsDetails/list_n.png'),
			list_y: require('@/assets/img/goodsDetails/list_y.png'),
			lists_n: require('@/assets/img/goodsDetails/lists_n.png'),
			lists_y: require('@/assets/img/goodsDetails/lists_y.png'),
			classifyData: [],
			secondLevel: [],
			threeLevel: [],
			classifyActive: null,
			secondLevelActive: null,
			threeActive: null,
			params: {
				page: 1,
				limit: 10,
				catId: '',
				catGrade: 1,
				goodsName: '',
				isPrice: null,
				isSales: null,
				token: this.$store.state.userData.token,
				isRecommend: 0,
			},
			total: 0,
			titleName: '', //导航名称
			titleNames: [], //点击组合导航名称
			goodsList: [],
			catGrade: null, //分类等级
			goodsGrade: null, //分类二级、三级显示隐藏
			gradeData: {
				grades: null,
				gradess: null,
				gradesss: null,
			}, //用来分类的显示高亮
		}
	},
	computed: {
		searchValue() {
			return this.$store.state.searchValue //这里返回vuex的state里的str数据
		},
	},
	// 监听路由变化
	watch: {
		$route: 'urlName',
		searchValue: {
			handler(nval, oval) {
				this.params.goodsName = nval
				this.init()
			},
			deep: true, // 深度监听
			immediate: true, //立即执行
		},
	},
	mounted() {
		this.params.catId = this.$route.query.id
		this.catGrade = this.$route.query.catGrade //分类等级
		this.params.catGrade = this.$route.query.catGrade //分类等级
		this.goodsGrade = this.$route.query.goodsGrade //二级、三级显示隐藏

		if (this.$store.state.searchValue != '') {
			this.params.goodsName = this.$store.state.searchValue
		}
		if (this.$route.query.id || this.$route.query.name) {
			this.params.isRecommend = 0
		} else {
			this.params.isRecommend = 1
			this.classifyActive = null
			this.secondLevelActive = null
			this.threeActive = null
		}
		this.gradeData = JSON.parse(localStorage.getItem('gradeData'))

		if (this.gradeData != null) {
			this.classifyActive = this.gradeData.grades
			this.secondLevelActive = this.gradeData.gradess
			this.threeActive = this.gradeData.gradesss
		}
		this.init()
		this.getData()
	},
	beforeDestroy() {
		sessionStorage.removeItem('searchValue')
		this.$store.commit('searchValue', '')
	},

	methods: {
		//获取分类
		getData() {
			this.api.findHomeData().then((res) => {
				this.classifyData = res.data.productClassification
				this.secondLevel = res.data.productClassification[0].children
				this.threeLevel = res.data.productClassification[0].children[0].children
				if (this.gradeData != null) {
					if (this.catGrade == 1) {
						let aaa = this.classifyData.find(
							(item) => item.id == this.gradeData.grades
						)
						this.secondLevel = aaa.children
					} else if (this.catGrade == 2 || this.catGrade == 3) {
						let aaa = this.classifyData.find(
							(item) => item.id == this.gradeData.grades
						)
						this.secondLevel = aaa.children
						let bbb = this.secondLevel.find(
							(item) => item.id == this.gradeData.gradess
						)
						this.threeLevel = bbb.children
					}
				}
			})
		},
		//获取商品列表
		init() {
			this.titleName = this.$route.query.navName //导航名
			this.api.findGoodsList(this.params).then((res) => {
				if (res.code == 0) {
					this.goodsList = res.data || []

					this.total = res.count
					if (res.count == 0) {
						this.getRecommendGoods()
					}
					this.getSearchRecord()
				}
			})
		},
		// 获取推荐商品
		getRecommendGoods() {
			this.api
				.findRecommendGoods({ token: this.$store.state.userData.token })
				.then((res) => {
					this.goodsList = res.data || []
				})
		},
		//搜索记录
		getSearchRecord() {
			let params = {
				token: this.$store.state.userData.token,
			}
			this.api.findGoodsSysConfig(params).then((res) => {
				if (res.code == 0) {
				}
			})
		},
		// 监听url参数变化
		urlName() {
			this.init()
		},
		// 点击一级分类
		classifyDataBtn(index, item) {
			this.params.isRecommend = 0
			this.params.page = 1
			this.goodsGrade = 2
			this.classifyActive = item.id
			if (this.params.isRecommend == 1) {
				this.secondLevelActive = null
				this.threeActive = null
			} else {
				this.secondLevelActive = null
				this.threeActive = null
			}
			this.secondLevel = item.children
			this.threeLevel = item.children[0].children
			this.params.catId = item.id
			this.params.catGrade = 1
			this.gradeData = {
				grades: item.id,
				gradess: null,
				gradesss: null,
			}

			this.titleNames = item.label //组合导航名称
			localStorage.setItem('gradeData', JSON.stringify(this.gradeData))
			this.params.goodsName = '' //清空搜索
			this.$store.commit('getSearcNum', '')
			this.$router.push({
				path: '/mall/goodsList',
				query: {
					id: item.id,
					catGrade: 1,
					goodsGrade: 2,
					navName: item.label,
				},
			})
			this.init()
		},
		// 点击二级分类
		secondLevelBtn(index, item) {
			this.params.page = 1
			this.params.isRecommend = 0
			this.goodsGrade = 3
			this.secondLevelActive = item.id

			if (this.params.isRecommend == 1) {
				this.threeActive = null
			} else {
				this.threeActive = null
			}
			this.threeLevel = item.children
			this.params.catId = item.id
			this.params.catGrade = 2

			if (this.gradeData == null) {
				this.gradeData = {
					grades: this.classifyData[0].id,
					gradess: item.id,
					gradesss: null,
				}
			} else {
				this.gradeData.gradess = item.id
				this.classifyActive = this.gradeData.grades
			}
			//获取一级商品名称
			let LevelS = this.classifyData.find(
				(item) => item.id == this.gradeData.grades
			)
			// 拼接商品名称
			this.classifyActive = LevelS.id
			this.titleNames = LevelS.label + '>' + item.label

			localStorage.setItem('gradeData', JSON.stringify(this.gradeData))
			this.params.goodsName = '' //清空搜索
			this.$store.commit('getSearcNum', '')
			// this.titleNames[1] = ''+item.label
			// this.titleNames[2] = ''
			this.$router.push({
				path: '/mall/goodsList',
				query: {
					id: item.id,
					catGrade: 2,
					goodsGrade: 3,
					navName: this.titleNames,
				},
			})
			this.init()
		},
		// 点击三级分类
		threeLevelBtn(index, item) {
			this.params.page = 1
			this.params.isRecommend = 0
			this.threeActive = item.id
			this.params.catId = item.id
			this.params.catGrade = 3

			if (this.gradeData == null) {
				this.classifyActive = this.classifyData[0].id
				this.secondLevelActive = this.secondLevel[0].id
				this.gradeData = {
					grades: this.classifyData[0].id,
					gradess: this.secondLevel[0].id,
					gradesss: item.id,
				}
			} else {
				this.gradeData.gradesss = item.id
			}

			localStorage.setItem('gradeData', JSON.stringify(this.gradeData))
			//获取一级商品名称
			let LevelS = this.classifyData.find(
				(item) => item.id == this.gradeData.grades
			)
			//获取二级商品名称
			let LevelSs = this.secondLevel.find(
				(item) => item.id == this.gradeData.gradess
			)
			if (LevelSs == undefined) {
				LevelSs = LevelS.children[0]
				this.secondLevelActive = LevelS.children[0].id
			}
			// 拼接商品名称
			this.titleNames = LevelS.label + '>' + LevelSs.label + '>' + item.label
			this.params.goodsName = '' //清空搜索
			this.$store.commit('getSearcNum', '')
			this.$router.push({
				path: '/mall/goodsList',
				query: {
					id: item.id,
					catGrade: 3,
					goodsGrade: 3,
					navName: this.titleNames.toString(this.titleNames),
				},
			})
			this.init()
		},
		//销售额排序
		changeSaleseBtn() {
			if (this.params.isSales == 1) {
				this.params.isSales = 2
			} else {
				this.params.isSales = 1
			}
			this.init()
		},
		//价格排序
		changePriceBtn() {
			if (this.params.isPrice == 1) {
				this.params.isPrice = 2
			} else {
				this.params.isPrice = 1
			}
			this.init()
		},
		// 收藏
		collectBtn(item) {
			let params = {
				productId: item.id,
				token: this.$store.state.userData.token,
			}
			if (item.isCollect == 0) {
				this.api.addCollec(params).then((res) => {
					if (res.code == 0) {
						this.$message.success('收藏成功！')
						this.init()
					} else {
						if (res.msg == 'token不能为空') {
							this.$router.push({
								path: '/login',
								query: {
									isLogin: false,
								},
							})
						} else {
							this.$message.error(res.msg)
						}
					}
				})
			} else {
				this.api.delCollect(params).then((res) => {
					if (res.code == 0) {
						this.$message.success('取消收藏成功！')
						this.init()
					} else {
						if (res.msg == 'token不能为空') {
							this.$router.push({
								path: '/login',
								query: {
									isLogin: 'no',
								},
							})
						} else {
							this.$message.error(res.msg)
						}
					}
				})
			}

			this.init()
		},
		// 大图-列表
		changeList(num) {
			this.isList = num
		},
		//分页
		pageSkip(page) {
			this.params = page
			this.init()
		},
	},
}
</script>
<style lang="scss" scoped>
.goods_list_box {
	.goods_list_content {
		width: 1200px;
		margin: 0 auto;
		.title {
			width: 100%;
			height: 57px;
			font-size: 12px;
			color: #666666;
		}
		.classification_box {
			background: white;
			.list {
				padding: 23px 21px 20px 21px;
				box-sizing: border-box;
				border-bottom: 1px solid #ebebeb;
				.level {
					font-size: 14px;
					color: #888888;
					margin-right: 27px;
					line-height: 30px;
				}
				.classification_list {
					.classification_name {
						font-size: 14px;
						color: #333333;
						margin-right: 26px;
						line-height: 30px;
					}
				}
			}
		}
		.sorting_box {
			width: 100%;
			height: 45px;
			background: white;
			margin-top: 11px;
			margin-bottom: 10px;
			.sorting_fl {
				.sorting {
					width: 98px;
					height: 44px;
					background: #e62129;
					color: #fff;
				}
				.sales {
					width: 95px;
					height: 44px;
					border-right: 1px solid #f6f6f6;
					img {
						width: 8px;
						height: 14px;
						margin-left: 5px;
					}
				}
			}
			.sorting_fr {
				.goodsNum {
					font-size: 14px;
					color: #aeaeae;
					margin-right: 13px;
				}
				.list {
					width: 82px;
					height: 45px;
					font-size: 14px;
					color: #666666;
					border-right: 1px solid #f6f6f6;
					img {
						width: 18px;
						height: 13px;
						margin-right: 5px;
					}
				}
				.bigImg {
					border-left: 1px solid #f6f6f6;
					img {
						width: 17px;
						height: 17px;
					}
				}
			}
		}
	}
	.active {
		color: #e62129 !important;
	}
	.pageBox {
		display: flex;
		justify-content: flex-end;
		margin-top: 31px;
		margin-bottom: 52px;
	}
	.noGoods_box {
		width: 1200px;
		height: 60px;
		background: #fff5e6;
		margin-bottom: 11px;
		padding-left: 65px;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		.icon_noData {
			width: 90px;
			height: 60px;
		}
		p {
			margin-left: 10px;
			span {
				color: #e62129;
			}
		}
	}
}
</style>