<template>
    <div class="goodsList_box flex flex-wrap">
        <div class="list Between pointer"  v-for="(item,index) in goodsList " :key="index" @click="toDetails(item.id)">
           <div class="list_fl flex-center" >
                <div class="goodsImg">
                    <img :src="item.coverUrl" alt="">
                </div>
                <div class="goodsName_specification">
                    <div class="goodsName row" v-html="item.productName"></div>
                    <div class="specification">商品型号：5996AA3253</div>

                </div>
           </div>
           <div class="goodsPrice">￥{{item.price.toFixed(2)}}</div>
          
                <div class="btn_box flex">
                    <div class="btn collect display"  @click.stop="collectBtn(item)">
                        <img :src="item.isCollect == 1?collect_y:collect_n" alt="">{{item.isCollect == 1?'已收藏':'收藏'}} 
                    </div>
                    <div class="btn cart display">
                    <img src="@/assets/img/goodsDetails/cart.png" alt=""> 购买
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        goodsList:{
            type:Array
        }
    },
    data(){
        return{
            collect_n:require('@/assets/img/goodsDetails/collect_n.png'),
            collect_y:require('@/assets/img/goodsDetails/collect_y.png'),
            
        }
    },
    methods:{
        //收藏
        collectBtn(item){
            this.$emit('collectBtn',item)
        },
        //去详情
        toDetails(id){
            this.$router.push({
                path:"/mall/goodsDetails",
                query:{
                    id:id
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
    .goodsList_box{
        .list:hover{
            border: 1px solid #E62129;
            box-sizing: border-box;
        }
        .list{
            width: 1200px;
            height: 100px;
            background: #FFFFFF;
            border: 1px solid #FFFFFF;
            padding: 0px 31px;
            box-sizing: border-box;
            margin-bottom: 10px;
            .list_fl{
                .goodsImg{
                    width: 78px;
                    img{
                        width: 78px;
                        height: 78px;
                    }
                }
                .goodsName_specification{
                    margin-left: 55px;
                    .goodsName{
                        width: 334px;
                        font-size: 12px;
                        color: #333333;
                    }
                    .specification{
                        font-size: 12px;
                        color: #888888;
                        margin-top: 19px;
                    }
                }
            }
            .goodsPrice{
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #E1251B;
            }
            .btn_box{
                .btn{
                    width: 84px;
                    height: 36px;
                    border-radius: 3px;
                    font-size: 14px;
                    color: #333333;
                    img{
                        width: 17px;
                        height: 17px;
                        margin-right: 10px;
                    }
                }
                .collect{
                    border: 1px solid #D0D0D0;
                }
                .cart{
                    background: #E62129;
                    color: #fff;
                    margin-left: 22px;
                }
            }
        }
    }
</style>